import React from "react";
import "./qrfooter.css";

function Qrfooter(){

    const d = new Date();
    const year = d.getFullYear();

    return(
        <div>
            <div className="qr_footer">
                <p>&#169; {year} All rights reserved</p>
                <div className="footer_links">
                    <a href="/terms">Terms and Conditions</a>
                    <a href="/privacy">Privacy Policy</a>
                    <a href="/about">About Us</a>
                    <a href="https://forms.gle/uzeK5XZr2zFArynEA">Contact</a>
                </div>
            </div>
        </div>
    );
}

export default Qrfooter;
