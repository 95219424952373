import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './Main';
import Decrypt from './Decrypt';
import Privacy from './privacy';
import Terms from './Terms';
import About from './About';
{/*import Submain from './Submain'; */}

function App() {
    return (
      <div>
        {/* <Main /> */}
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/" element={<Main />} />
           { /*<Route path="/scanner" element={<Submain />} /> */}
           <Route path="/aes_decrypter" element={<Decrypt />} />
           <Route path="/privacy" element={<Privacy />} />
           <Route path="/terms" element={<Terms /> } />
           <Route path="/about" element={<About /> } />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }

  export default App