import React from 'react';
import Qrdash from './qrcode/Qrdash/qrdash';
import Qrnav from './qrcode/Qrdash/Qrnav/qrnav';
import Qrfooter from './qrcode/Qrdash/Qrfooter/qrfooter';
import Qrfaq from './qrcode/Qrdash/Qrfaq/qrfaq';

function Main() {
    return (
      <div>
        <Qrnav />
        <Qrdash />
        <Qrfaq />
        <Qrfooter />
      </div>
    )
  }
  
  export default Main;
  