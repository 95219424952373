import React from "react";
import './qrprivacy.css'; // Adjust this path as per your project structure

function Qrprivacy() {
    return (
        <div className="initial_pos">
            <div className="headerqr"><h1>Privacy Policy</h1></div>

            <div className="privacy-policy-content">
                <p>Last updated: 18th June 2024.</p>

                <p>We operates the Evolve QR React application (the "Service").</p>

                <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

                <h3>Information Collection and Use</h3>

                <p>We do not collect any personal data directly through our Service. Any data provided by you, such as input values for generating QR codes or email addresses for sending QR codes, is processed locally within your browser and is not stored or transmitted to our servers.</p>

                <h3>Third-Party Services and Ads</h3>

                <p>Our Service may display third-party advertisements. These advertisements may use cookies or similar technologies to provide relevant ads. We do not have control over these third-party services or their privacy practices. Please consult the privacy policies of these third parties for more information on how they use cookies and collect information.</p>

                <h3>Security</h3>

                <p>The security of your data is important to us, but please remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                <h3>Links to Other Sites</h3>

                <p>Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>

                <h3>Children's Privacy</h3>

                <p>Our Service does not address anyone under the age of 13 ("Children"). We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your Children have provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

                <h3>Changes to This Privacy Policy</h3>

                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            </div>
        </div>
    );
}

export default Qrprivacy;
