import React from 'react';
import Qrnav from './qrcode/Qrdash/Qrnav/qrnav';
import Qrfooter from './qrcode/Qrdash/Qrfooter/qrfooter';
import Qrfaq from './qrcode/Qrdash/Qrfaq/qrfaq';
import Qrabout from './qrcode/Qrdash/Qrabout/qrabout';


function About() {
    return (
      <div>
        <Qrnav />
        <Qrabout />
        <Qrfaq />
        <Qrfooter />
      </div>
    )
  }
  
  export default About;
  