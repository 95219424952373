import React, { useState } from "react";
import "./qrfaq.css";

const Qrfaq = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleDropdown = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div id="faq_direct">
            <div className="content_faq">
                <div className="faq-section">
                    <h1>FAQ - Evolve QR</h1>
                    <h2>General Instructions</h2>
                    <p>Welcome to the Evolve QR. Follow these instructions to create, encrypt, and share QR codes:</p>
                    <ul>
                        <li>Enter the values you want to convert into QR codes in the input field, separated by spaces.</li>
                        <li>Enter the recipient email addresses, also separated by spaces.</li>
                        <li>Choose whether you want to encrypt the QR code data using AES encryption.</li>
                        <li>Generate and preview the QR codes, and navigate through them using the provided buttons.</li>
                        <li>Send the QR codes to the selected recipients via email.</li>
                        <li>Download the QR codes in your preferred format (PNG, JPG, PDF, or all in a single PDF).</li>
                    </ul>
                </div>

                <div id="faq_redirect" className="faq-section">
                    <h2>Common Questions</h2>
                    {[
                        {
                            question: "How do I generate an app password?",
                            answer: (
                                <>
                                    <p>To generate an app password for your Gmail account:</p>
                                    <ul>
                                        <li>Go to your Google Account.</li>
                                        <li>Select "Security" from the left navigation panel.</li>
                                        <li>Under "Signing in to Google," select "App passwords." You may need to sign in again.</li>
                                        <li>At the bottom, choose "Select app" and "Select device" and pick the app and device you’re using.</li>
                                        <li>Select "Generate." Follow the instructions to enter the Gmail app password (a 16-character code) in the app.</li>
                                        <li>Select "Done."</li>
                                    </ul>
                                    <p>Note: If you might not see the Gmail App passwords option:</p>
                                    <ul>
                                        <li>Set up 2-Step Verification for your account.</li>
                                        <li>2-Step Verification is only set up for security keys.</li>
                                        <li>Search in the search bar of manage google account for Gmail App password.</li>
                                        <li>Search on Youtube and Google for more help regarding Gmail App password</li>
                                    </ul>
                                </>
                            ),
                        },
                        {
                            question: "Can I use 'Send all the QR codes to respective recipient Email ID' button to send all the respective individual QR codes to their respective email addresses at once?",
                            answer: (
                                <p>Yes, click the "Send all the QR codes to respective recipient's Email ID" button to send each QR code to its corresponding email address respectively. Ensure that the number of QR codes matches the number of email addresses. Example: Let's say you entered three different values in QR value input field to generate three different QR codes (Eg: x, y, z), then enter three different email id in recipient's email id input field (Eg: person1@gmail.com, person2@gmail.com, person3@gmail.com), now click the "Send all the QR codes to respective recipient's Email ID" button and QR code with value X will be send to person1@gmail.com, QR code with value y will be send to person2@gmail.com, QR code with value z will be send to person3@gmail.com respectively.</p>
                            ),
                        },
                        {
                            question: "Which Encryption algorithm is used to encrypt the data? (If encryption is set to \"ON\")",
                            answer: (
                                <p>AES based crypto-js Encryption has been used. You will have to create your own Encryption key in the options provided otherwise if the input field for "Enter a custom secret key for encryption" is left blank then the default encryption key is "your-secret-key". <a className="instruction_anc" href="https://cryptojs.gitbook.io/docs">Click here</a> to read more about AES Encryption.</p>
                            ),
                        },
                        {
                            question: "How do I generate a QR code?",
                            answer: (
                                <p>Simply enter the values for which you want to generate the QRcode into the input field, separated by spaces. (Example: Urls, mobile number, text, unique_id etc.(You can enter any type of value.)) The QR codes will be generated automatically.</p>
                            ),
                        },
                        {
                            question: "How do I encrypt the QR code data?",
                            answer: (
                                <p>Use the dropdown menu to select "Encryption On". The QR code data will be encrypted using AES encryption with your secret key.</p>
                            ),
                        },
                        {
                            question: "How do I download the QR codes?",
                            answer: (
                                <p>Use the dropdown menus to select your download options (selected QR code or all QR codes) and the desired format (PNG, JPG, PDF, or all in a single PDF). Click the "Download QR Codes" button to initiate the download.</p>
                            ),
                        },
                        {
                            question: "What do I need to provide for sending emails?",
                            answer: (
                                <p>You'll need to provide your email address and your Gmail app password. Enter these details in the corresponding input fields to enable the email sending functionality.</p>
                            ),
                        },
                        {
                            question: "How do I navigate between multiple QR codes?",
                            answer: (
                                <p>Use the arrow buttons to navigate between the QR codes. The current QR code and its corresponding value will be displayed in the preview section.</p>
                            ),
                        },
                        {
                            question: "Can I include a custom message in the email?",
                            answer: (
                                <p>Yes, you can enter a custom message in the provided textarea. This message will be included in the email along with the QR code.</p>
                            ),
                        },
                        {
                            question: "What if the QR code and email counts don't match?",
                            answer: (
                                <p>Ensure that the number of QR codes matches the number of email addresses. If they don't match, you'll receive an alert to correct the entries.</p>
                            ),
                        },
                        {
                            question: "How do I download all QR codes in a single PDF?",
                            answer: (
                                <p>Select the "Download All QRs" option and choose "Download all in single PDF" from the format dropdown. Click "Download QR Codes" to generate the PDF.</p>
                            ),
                        },
                        {
                            question: "Can I choose my own secret key for encryption?",
                            answer: (
                                <p>Yes, you can enter a custom secret key for encryption. Ensure to securely share this key with intended recipients for decryption. If you choose to encrypt without encryption key then default key is "your-secret-key".</p>
                            ),
                        },
                        {
                            question: "Is encryption necessary for QR codes?",
                            answer: (
                                <p>Encryption adds an extra layer of security by scrambling QR code data. It's recommended for sensitive information, such as personal details or confidential messages.</p>
                            ),
                        },
                        {
                            question: "How do I toggle encryption on or off?",
                            answer: (
                                <p>You can switch encryption on or off using the dropdown menu labeled "Encryption On". Turning it off will encode QR codes without encryption.</p>
                            ),
                        },
                        {
                            question: "What happens if I forget my secret key?",
                            answer: (
                                <p>If you forget your secret key, you may lose access to encrypted QR code data. Keep the key secure and accessible only to authorized users.</p>
                            ),
                        },
                        {
                            question: "Can encrypted QR codes be scanned by any QR code scanner?",
                            answer: (
                                <p>Yes, encrypted QR codes can be scanned by any QR code scanner. However, decryption requires the correct secret key.</p>
                            ),
                        },
                        {
                            question: "How do I ensure secure communication of secret keys?",
                            answer: (
                                <p>Share secret keys through secure channels like encrypted emails or direct communication. Avoid sharing them publicly or via unsecured methods.</p>
                            ),
                        },
                        {
                            question: "What are the benefits of using encryption in QR codes?",
                            answer: (
                                <p>Encryption protects sensitive data from unauthorized access and ensures confidentiality during QR code transmission and storage.</p>
                            ),
                        },
                        {
                            question: "Can I change the encryption settings after generating QR codes?",
                            answer: (
                                <p>Yes, you can toggle encryption on or off and update the secret key. Changes will apply to subsequent QR code generations.</p>
                            ),
                        },
                        {
                            question: "What should I put for Decryption key in the field \"Enter your secret key\"?",
                            answer: (
                                <p>Enter the same key you made for encrypting the QR code.</p>
                            ),
                        }
                    ].map((faq, index) => (
                        <div className="faq-dropdown" key={index} onClick={() => toggleDropdown(index)}>
                            <li className="faq-question">{faq.question} <span className="arrow">{openIndex === index ? "▼" : "►"}</span></li>
                            {openIndex === index && (
                                <div className="faq-answer">{faq.answer}</div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="faq-section">
                    <h2>Troubleshooting</h2>
                    {[
                        {
                            question: "I can't generate QR codes. What should I do?",
                            answer: (
                                <p>Ensure that you have entered values in the input field. If the problem persists, try refreshing the page or restarting your browser.</p>
                            ),
                        },
                        {
                            question: "I'm having trouble sending emails. What could be wrong?",
                            answer: (
                                <p>Check that you have entered valid email addresses and provided your Gmail app password. Ensure that your internet connection is stable.</p>
                            ),
                        },
                        {
                            question: "The QR codes are not downloading. What can I do?",
                            answer: (
                                <p>Ensure that you have selected a download format and option. If the issue continues, try clearing your browser cache or using a different browser.</p>
                            ),
                        }
                    ].map((faq, index) => (
                        <div className="faq-dropdown" key={index} onClick={() => toggleDropdown(index + 20)}>
                            <li className="faq-question">{faq.question} <span className="arrow">{openIndex === index + 20 ? "▼" : "►"}</span></li>
                            {openIndex === index + 20 && (
                                <div className="faq-answer">{faq.answer}</div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Qrfaq;
