import React, { useState } from "react";
import CryptoJS from 'crypto-js';
import './qrdecrypt.css'; 
import { useNavigate } from 'react-router-dom';

const defaultSecretKey = 'your-secret-key'; // Default secret key

function Qrdecrypt() {
    const [encryptedText, setEncryptedText] = useState('');
    const [decryptedText, setDecryptedText] = useState('');
    const [userSecretKey, setUserSecretKey] = useState(''); // State for user-provided secret key

    const handleEncryptedTextChange = (event) => {
        setEncryptedText(event.target.value);
    };

    const navigate = useNavigate();

    const handleSecretKeyChange = (event) => {
        setUserSecretKey(event.target.value);
    };

    const decryptData = (data) => {
        try {
            const key = userSecretKey || defaultSecretKey;
            const bytes = CryptoJS.AES.decrypt(data, key);
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            if (!originalText) throw new Error('Invalid key or data');
            return originalText;
        } catch (error) {
            return 'Decryption failed: Invalid key or data';
        }
    };

    const handleDecrypt = () => {
        const result = decryptData(encryptedText);
        setDecryptedText(result);
    };

    return (
        <div className="initial_pos">
         <div className="scanner_btn">
    <button className='button_nav' onClick={()=>{navigate('/')}}>QR Generator</button>
    </div> 
            <div className="headerqr"><h1>AES based QR Code Decrypter</h1></div>
            <div className="userinput">
                <input 
                    size={25}
                    type="text" 
                    placeholder="Enter encrypted text" 
                    value={encryptedText}
                    onChange={handleEncryptedTextChange} 
                />
            </div>
            <div className="userinput">
                <input 
                    size={25}
                    type="text" 
                    placeholder="Enter your secret key" 
                    value={userSecretKey}
                    onChange={handleSecretKeyChange} 
                />
            </div>
            <div>
                <button onClick={handleDecrypt}>Decrypt</button>
            </div>
            <div className="result-box">
                <textarea
                    rows={4}
                    cols={25}
                    readOnly
                    value={decryptedText || "No result"}
                />
            </div>
        </div>
    );
}

export default Qrdecrypt;
