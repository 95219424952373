import React, { useState, useEffect } from "react";
import './qrdash.css';
import QRCode from "react-qr-code";
import CryptoJS from 'crypto-js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import { useNavigate } from 'react-router-dom';

const defaultSecretKey = 'your-secret-key'; // Default secret key

function Qrdash() {
    const [inputValue, setInputValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const [dropdownValues, setDropdownValues] = useState([]);
    const [emailAddresses, setEmailAddresses] = useState([]);
    const [selectedQrIndex, setSelectedQrIndex] = useState(0);
    const [selectedEmail, setSelectedEmail] = useState('');
    const [qrValues, setQrValues] = useState([]);
    const [encryptionOn, setEncryptionOn] = useState(false); // Default to encryption off
    const [downloadOption, setDownloadOption] = useState('selected'); // State for download option
    const [selectedDownloadFormat, setSelectedDownloadFormat] = useState(''); // State for download format
    const [senderEmail, setSenderEmail] = useState(''); // State for sender email
    const [senderPassword, setSenderPassword] = useState(''); // State for sender password
    const [userSecretKey, setUserSecretKey] = useState(''); // State for user-provided secret key
    const [qrColor, setQrColor] = useState('#000000'); // State for QR code color


    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        const valuesArray = value.split(' ').filter(v => v); // Split by space and filter out empty values
        setDropdownValues(valuesArray);
        setQrValues(valuesArray.map(value => encryptData(value)));
        setSelectedQrIndex(0);
    };

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmailValue(value);
        const emailsArray = value.split(' ').filter(v => v); // Split by space and filter out empty values
        setEmailAddresses(emailsArray);
    };

    const handleCustomMessageChange = (event) => {
        setCustomMessage(event.target.value);
    };

    const handleQrDropdownChange = (event) => {
        const selectedIndex = dropdownValues.indexOf(event.target.value);
        setSelectedQrIndex(selectedIndex);
    };

    const handleEmailDropdownChange = (event) => {
        const selectedEmail = event.target.value;
        setSelectedEmail(selectedEmail);
    };

    const encryptData = (data) => {
        if (encryptionOn) {
            const key = userSecretKey || defaultSecretKey;
            return CryptoJS.AES.encrypt(data, key).toString();
        } else {
            return data;
        }
    };

    const handleSendEmail = () => {
        if (!qrValues.length || !selectedEmail || !senderEmail || !senderPassword) {
            alert('Please enter QR values, an email address, and sender email credentials.');
            return;
        }

        if (selectedEmail === 'all') {
            emailAddresses.forEach(email => {
                sendEmail(qrValues[selectedQrIndex], email);
            });
        } else {
            sendEmail(qrValues[selectedQrIndex], selectedEmail);
        }
    };

    const sendEmail = (qrCode, email) => {
        // Convert QR code to base64
        const canvas = document.createElement('canvas');
        const qrCodeSVG = document.querySelector('svg');
        const svgData = new XMLSerializer().serializeToString(qrCodeSVG);
        const img = new Image();
        img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const base64Image = canvas.toDataURL('image/png');

            fetch('https://qr-system-backend.vercel.app/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    qrCode: base64Image,
                    email,
                    customMessage,
                    senderEmail, // Add sender email
                    senderPassword // Add sender password
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                alert(`Email sent successfully to ${email}`);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        };
    };

    const handlePrevQr = () => {
        setSelectedQrIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    };

    const handleNextQr = () => {
        setSelectedQrIndex((prevIndex) => (prevIndex < qrValues.length - 1 ? prevIndex + 1 : prevIndex));
    };

    const handleSendToAll = () => {
        if (!qrValues.length || !emailAddresses.length || qrValues.length !== emailAddresses.length) {
            alert('Please enter QR values and email addresses or check if number of email and QR value is same');
            return;
        }

        let index = 0;

        const sendNextEmail = () => {
            if (index < qrValues.length && index < emailAddresses.length) {
                setSelectedQrIndex(index);
                setSelectedEmail(emailAddresses[index]);

                setTimeout(() => {
                    sendEmail(qrValues[index], emailAddresses[index]);
                    index++;
                    sendNextEmail();
                }, 500); // Adding delay to ensure QR code generation and email sending
            } else {
                alert('All emails have been sent.');
            }
        };

        sendNextEmail();
    };

    const handleEncryptionChange = (event) => {
        const encryption = event.target.value === 'on';
        setEncryptionOn(encryption);
        if (!encryption) {
            setUserSecretKey(''); // Clear the user-provided secret key when encryption is turned off
        }
    };

    const downloadImage = (format) => {
        const qrCodeElement = document.querySelector('.qr-code-container');
        html2canvas(qrCodeElement).then(canvas => {
            const link = document.createElement('a');
            if (format === 'png') {
                link.href = canvas.toDataURL('image/png');
                link.download = 'qrcode.png';
            } else if (format === 'jpg') {
                link.href = canvas.toDataURL('image/jpeg');
                link.download = 'qrcode.jpg';
            }
            link.click();
        });
    };

    const downloadAllImages = (format) => {
        const zip = new JSZip();
        const downloadNextImage = (index) => {
            if (index < qrValues.length) {
                setSelectedQrIndex(index);
                setTimeout(() => {
                    const qrCodeElement = document.querySelector('.qr-code-container');
                    html2canvas(qrCodeElement).then(canvas => {
                        canvas.toBlob(blob => {
                            const fileName = `qrcode_${index + 1}.${format}`;
                            zip.file(fileName, blob);
                            if (index === qrValues.length - 1) {
                                zip.generateAsync({ type: 'blob' }).then(content => {
                                    const link = document.createElement('a');
                                    link.href = URL.createObjectURL(content);
                                    link.download = 'qrcodes.zip';
                                    link.click();
                                });
                            } else {
                                downloadNextImage(index + 1);
                            }
                        });
                    });
                }, 500); // Adding delay to ensure QR code generation
            }
        };
        downloadNextImage(0);
    };
    
    const downloadPdf = () => {
        const qrCodeElement = document.querySelector('.qr-code-container');
        html2canvas(qrCodeElement).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 10, 10, 180, 180);
            pdf.save('qrcode.pdf');
        });
    };

    const downloadAllPdfs = () => {
        const zip = new JSZip();
        const downloadNextPdf = (index) => {
            if (index < qrValues.length) {
                setSelectedQrIndex(index);
                setTimeout(() => {
                    const qrCodeElement = document.querySelector('.qr-code-container');
                    html2canvas(qrCodeElement).then(canvas => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF();
                        pdf.addImage(imgData, 'PNG', 10, 10, 180, 180);
                        const pdfBlob = pdf.output('blob');
                        zip.file(`qrcode_${index + 1}.pdf`, pdfBlob);
                        if (index === qrValues.length - 1) {
                            zip.generateAsync({ type: 'blob' }).then(content => {
                                const link = document.createElement('a');
                                link.href = URL.createObjectURL(content);
                                link.download = 'qrcodes.zip';
                                link.click();
                            });
                        } else {
                            downloadNextPdf(index + 1);
                        }
                    });
                }, 500); // Adding delay to ensure QR code generation
            }
        };
        downloadNextPdf(0);
    };
    

    const downloadAllQrInSinglePdf = () => {
        const pdf = new jsPDF();
        const generatePdfPage = (index) => {
            if (index < qrValues.length) {
                setSelectedQrIndex(index);
                setTimeout(() => {
                    const qrCodeElement = document.querySelector('.qr-code-container');
                    html2canvas(qrCodeElement).then(canvas => {
                        const imgData = canvas.toDataURL('image/png');
                        pdf.addImage(imgData, 'PNG', 10, 10, 180, 180);
                        if (index < qrValues.length - 1) {
                            pdf.addPage();
                        }
                        generatePdfPage(index + 1);
                    });
                }, 500); // Adding delay to ensure QR code generation
            } else {
                pdf.save('all_qrcodes.pdf');
            }
        };
        generatePdfPage(0);
    };

    const handleDownloadChange = (event) => {
        setSelectedDownloadFormat(event.target.value);
    };

    const handleDownloadOptionChange = (event) => {
        setDownloadOption(event.target.value);
    };

    const handleQrColorChange = (event) => {
        setQrColor(event.target.value);
    };
    

    const handleDownloadQRCodes = () => {
        const format = selectedDownloadFormat;
    
        if (qrValues.length === 0) {
            alert('No QR values entered. Please enter values to generate QR codes before downloading.');
            return;
        }
    
        if (!format) {
            alert('Please select a download format.');
            return;
        }
    
        if (downloadOption === 'selected') {
            if (format === 'png' || format === 'jpg') {
                downloadImage(format);
            } else if (format === 'pdf') {
                downloadPdf();
            } else if (format === 'singlepdf') {
                alert("First select 'Download All QRs' and then select 'Download all in single PDF'");
            }
        } else if (downloadOption === 'all') {
            if (format === 'png' || format === 'jpg') {
                downloadAllImagesInZip(format);
            } else if (format === 'pdf') {
                downloadAllPdfsInZip();
            } else if (format === 'singlepdf') {
                downloadAllQrInSinglePdf();
            }
        }
    };
    
    const downloadAllImagesInZip = (format) => {
        const zip = new JSZip();
        const downloadNextImage = (index) => {
            if (index < qrValues.length) {
                setSelectedQrIndex(index);
                setTimeout(() => {
                    const qrCodeElement = document.querySelector('.qr-code-container');
                    html2canvas(qrCodeElement).then(canvas => {
                        canvas.toBlob(blob => {
                            const fileName = `qrcode_${index + 1}.${format}`;
                            zip.file(fileName, blob);
                            if (index === qrValues.length - 1) {
                                zip.generateAsync({ type: 'blob' }).then(content => {
                                    const link = document.createElement('a');
                                    link.href = URL.createObjectURL(content);
                                    link.download = 'qrcodes.zip';
                                    link.click();
                                });
                            } else {
                                downloadNextImage(index + 1);
                            }
                        });
                    });
                }, 500); // Adding delay to ensure QR code generation
            }
        };
        downloadNextImage(0);
    };
    
    const downloadAllPdfsInZip = () => {
        const zip = new JSZip();
        const downloadNextPdf = (index) => {
            if (index < qrValues.length) {
                setSelectedQrIndex(index);
                setTimeout(() => {
                    const qrCodeElement = document.querySelector('.qr-code-container');
                    html2canvas(qrCodeElement).then(canvas => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF();
                        pdf.addImage(imgData, 'PNG', 10, 10, 180, 180);
                        const pdfBlob = pdf.output('blob');
                        zip.file(`qrcode_${index + 1}.pdf`, pdfBlob);
                        if (index === qrValues.length - 1) {
                            zip.generateAsync({ type: 'blob' }).then(content => {
                                const link = document.createElement('a');
                                link.href = URL.createObjectURL(content);
                                link.download = 'qrcodes.zip';
                                link.click();
                            });
                        } else {
                            downloadNextPdf(index + 1);
                        }
                    });
                }, 500); // Adding delay to ensure QR code generation
            }
        };
        downloadNextPdf(0);
    };
    
    
    // Use effect to re-compute qrValues whenever encryptionOn changes
    useEffect(() => {
        const valuesArray = inputValue.split(' ').filter(v => v); // Split by space and filter out empty values
        setQrValues(valuesArray.map(value => encryptData(value)));
    }, [encryptionOn, inputValue, userSecretKey]);

    const navigate = useNavigate();

    return (
        <div className="initial_pos">
         <div className="scanner_btn">
    <button className='button_nav' onClick={()=>{navigate('/aes_decrypter')}}>QR Decrypter</button>
    </div> 
            <div className="headerqr"><h1>Free Bulk QR Code Generator</h1></div>

            <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%", textAlign: "center" }}>
                {qrValues.length > 0 && (
                    <>
                        <button className="qr_btn_select" onClick={handlePrevQr} disabled={selectedQrIndex === 0}>⬅️</button>
                        <div className="qr-code-container">
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={qrValues[selectedQrIndex]}
                                viewBox={'0 0 256 256'}
                                fgColor={qrColor}
                            />
                        </div>
                        <button className="qr_btn_select" onClick={handleNextQr} disabled={selectedQrIndex === qrValues.length - 1}>➡️</button>
                        <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", color: "#000" }}>
                            {`${selectedQrIndex + 1}. ${dropdownValues[selectedQrIndex]}`}
                        </div>
                        <div className="userdropdow">
                            <select onChange={handleEncryptionChange} defaultValue="off">
                                <option value="on">Encryption On</option>
                                <option value="off">Encryption Off</option>
                            </select>
                        </div>

                        <div className="input-container colorbox_style">
                        <label>
                        QR Code Color:
                        <input type="color" value={qrColor} onChange={handleQrColorChange} />
                        </label>
                        </div>

                    </>
                )}
                {qrValues.length === 0 && <p>Input value to Display QR code</p>}
            </div>
            <div className="userinput">
                <input 
                    size={25}
                    type="text" 
                    placeholder="Enter any values separated by space" 
                    value={inputValue}
                    onChange={handleInputChange} 
                />
            </div>

            {encryptionOn && (
                <div className="userinput">
                    <input 
                        size={25}
                        type="text" 
                        placeholder=" Enter a custom secret key for encryption" 
                        value={userSecretKey}
                        onChange={(e) => setUserSecretKey(e.target.value)} 
                    />
                </div>
            )}
            <div className="userinput">
                <input 
                    size={25}
                    type="text" 
                    placeholder="Enter recipient emails separated by space" 
                    value={emailValue}
                    onChange={handleEmailChange} 
                />
            </div>
            <div className="userinput">
                <input 
                    size={25}
                    type="email" 
                    placeholder="Enter sender's Email ID" 
                    value={senderEmail}
                    onChange={(e) => setSenderEmail(e.target.value)} 
                />
            </div>
            <div className="userinput instruct_flex">
                <input 
                    size={25}
                    type="password" 
                    placeholder="Enter sender's Gmail app password" 
                    value={senderPassword}
                    onChange={(e) => setSenderPassword(e.target.value)} 
                />
                <p><a className='instruction_anc' href="#faq_redirect">Click Here</a> and read the highlighted FAQ to know more about Gmail App password.</p>
            </div>
            <div className="userinput">
                <textarea
                    rows={4}
                    cols={25}
                    placeholder="Enter your message which will be the content of the Email."
                    value={customMessage}
                    onChange={handleCustomMessageChange}
                />
            </div>
            <div className="dropdown_flex">
                <div className="userdropdown">
                    <select onChange={handleQrDropdownChange}>
                        <option value="">Select a QR value</option>
                        {dropdownValues.map((value, index) => (
                            <option key={index} value={value}>{`${index + 1}. ${value}`}</option>
                        ))}
                    </select>
                </div>
                <div className="userdropdown">
                    <select onChange={handleEmailDropdownChange}>
                        <option value="">Select Recipient's email</option>
                        <option value="all">Select All</option>
                        {emailAddresses.map((email, index) => (
                            <option key={index} value={email}>{`${index + 1}. ${email}`}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div>
                <button onClick={handleSendEmail}>Send QR Code to selected recipient's Email</button>
            </div>
            <div className='instruct_flex'>
                <button className="sendall_btn" onClick={handleSendToAll}>Send all the QR codes to respective recipient's Email ID </button>
                <p><a className='instruction_anc' href="#faq_redirect">Click Here</a> and read the highlighted FAQ to know more about the "send all" button.</p>
            </div>
            <div className="dropdown_flex">
                <div className="userdropdown">
                    <select onChange={handleDownloadOptionChange}>
                        <option value="selected">Download Selected QR</option>
                        <option value="all">Download All QRs in ZIP file</option>
                    </select>
                </div>
                <div className="userdropdown">
                    <select onChange={handleDownloadChange}>
                        <option value="">Download Format</option>
                        <option value="png">Download as PNG</option>
                        <option value="jpg">Download as JPG</option>
                        <option value="pdf">Download as PDF</option>
                        <option value="singlepdf">Download all in single PDF</option>
                    </select>
                </div>
            </div>
            <div>
                <button onClick={handleDownloadQRCodes}>Download QR Codes</button>
            </div>
           
            
        </div>
    );
}

export default Qrdash;
