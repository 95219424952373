import React from 'react';
import QrDecrypt from './qrdecrypter/Qrdecrypt/qrdecrypt';
import Qrnav from './qrcode/Qrdash/Qrnav/qrnav';
import Qrfooter from './qrcode/Qrdash/Qrfooter/qrfooter';
import Qrfaq from './qrcode/Qrdash/Qrfaq/qrfaq';


function Decrypt() {
    return (
      <div>
        <Qrnav />
        <QrDecrypt />
        <Qrfaq />
        <Qrfooter />
      </div>
    )
  }
  
  export default Decrypt;
  