import React from 'react';
import './qrterms.css'; // Import the CSS file

const Qrterms = () => {
    return (
        <div>
         <div className="headerqr"><h1>Terms and Conditions</h1></div>
        <div className="terms-conditions-content">
            
            <h3>1. Introduction</h3>
            <p>Welcome to our website. These terms and conditions outline the rules and regulations for the use of our website.</p>
            
            <h3>2. Acceptance of Terms</h3>
            <p>By accessing this website, you agree to comply with these terms and conditions. If you do not agree, please do not use our website.</p>
            
            <h3>3. Intellectual Property</h3>
            <p>The content on this website is the property of the developer and is protected by intellectual property laws.</p>
            
            <h3>4. Use of the Website</h3>
            <p>You agree to use the website for lawful purposes only and in a way that does not infringe on the rights of others or restrict their use and enjoyment of the website.</p>
            
            <h3>5. Disclaimer of Warranties</h3>
            <p>The website is provided on an "as is" and "as available" basis. We do not guarantee that the website will be uninterrupted or error-free.</p>
            
            <h3>6. Limitation of Liability</h3>
            <p>We will not be liable for any damages arising from the use or inability to use the website, including but not limited to direct, indirect, incidental, or consequential damages.</p>
            
            <h3>7. Changes to Terms and Conditions</h3>
            <p>We reserve the right to update or change these terms and conditions at any time. Any changes will be posted on this page.</p>
            
            <h3>8. Governing Law</h3>
            <p>These terms and conditions are governed by and construed in accordance with the laws of India.</p>
            
            <h3>9. Contact Information</h3>
            <p>If you have any questions about these terms and conditions, please contact us at <a href="https://forms.gle/uzeK5XZr2zFArynEA">Click here</a>.</p>
        </div>
        </div>
    );
}

export default Qrterms;
