import React from 'react';
import './qrabout.css'; // Import the CSS file

const Qrabout = () => {
    return (
        <div>
         <div className="headerqr"><h1>About Us</h1></div>
        <div className="about-us-content">
            <p>Welcome to <strong>Evolve QR</strong></p>
            <p>Your one-stop solution for creating, encrypting, and sharing QR codes effortlessly. Our innovative platform is designed to meet all your QR code needs, whether you're a business looking to streamline operations or an individual wanting to share information securely.</p>
            
            <h3>Our Mission</h3>
            <p>Our mission is to provide a powerful yet user-friendly tool for generating and managing QR codes. We aim to simplify the process of creating encrypted QR codes and ensure seamless sharing via email, making it easier for you to communicate and share information securely.</p>
            
            <h3>What We Offer</h3>
            <ul>
                <li><strong>Easy QR Code Generation:</strong> Enter your desired text, and our tool will generate a QR code instantly. You can create multiple QR codes from a single input by separating values with spaces.</li>
                <li><strong>Advanced Encryption:</strong> Ensure your data is secure with our optional AES encryption feature. Turn encryption on or off as needed to protect your sensitive information.</li>
                <li><strong>Email Integration:</strong> Effortlessly send QR codes to multiple recipients directly from our platform. Customize your email content with personalized messages.</li>
                <li><strong>Flexible Download Options:</strong> Choose from various download formats including PNG, JPG, and PDF. Download a single QR code, all QR codes, or compile all QR codes into a single PDF for convenience.</li>
                <li><strong>Automated Bulk Actions:</strong> Save time by sending all generated QR codes to their respective email recipients with just a few clicks.</li>
            </ul>
            
            <h3>Why Choose Us?</h3>
            <ul>
                <li><strong>User-Friendly Interface:</strong> Our platform is designed with simplicity in mind, ensuring that you can generate and manage QR codes with ease.</li>
                <li><strong>Customization:</strong> Tailor your QR codes and email messages to suit your needs. Our tool allows for extensive customization to match your requirements.</li>
                <li><strong>Security:</strong> We prioritize your data security with AES encryption, providing an additional layer of protection for your sensitive information.</li>
                <li><strong>Efficiency:</strong> With features like bulk email sending and multiple download formats, we help you save time and streamline your workflow.</li>
            </ul>
        </div>
        </div>
    );
}

export default Qrabout;
